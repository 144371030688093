.CustomInputBox {
    height: 2vw !important;
  }
  
  .AutoRefreshIcon {
    cursor: pointer;
    animation-play-state: paused;
  }
  
  .AutoRefreshIconLoading {
    cursor: pointer;
    color: green;
    font-weight: bold;
    animation: rotation 1s linear 0s infinite;
  }
  
  @keyframes rotation {
    to {
      transform: rotate(360deg);
    }
  }
  