.UserFromParent {
    min-width: 30vw;
}

.UserRoleParent {
    display: flex;
    justify-content: center;
}

.UserRole {
    padding: 0.3vw;
    border-radius: 0.5vw;
    color: #ffffff;
    width: 5vw;
    align-self: center;
}

.UserRoleAdmin {
    background-color: #3498db;
}

.UserRoleUser {
    background-color: #27ae60;
}
