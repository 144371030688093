.checkboxWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  user-select: none;
}

.label {
  margin-left: 0.2vw;
}

.inputWrapper {
  position: relative;
}

.input {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.input:checked+.checkmark {
  background-color: var(--color-primary);
  border: 0.1vw solid var(--color-primary);
}

.input:checked+.checkmark:after {
  display: block;
}

.checkmark {
  display: block;
  position: relative;
  height: 1vw;
  width: 1vw;
  background-color: #fff;
  border: 0.1vw solid #DEE4EB;
  border-radius: 0.4vw;
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
  left: 0.33vw;
  top: 0.05vw;
  width: 0.2vw;
  height: 0.6vw;
  border: solid #fff;
  border-width: 0 0.15vw 0.15vw 0;
  transform: rotate(45deg);
}